body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Roboto', 'Oxygen', 'Cantarell', 
  'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-image: url("./Images/fondo.png");
  height: 1080px;
  width: 100%;
  background-attachment: fixed;
  font-size: 20px;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#active .active{
  background-color: #FE2621 !important;
}

.fondoNosotros{
  background-image: url("./Images/4.png");
  background-attachment: fixed;
  height: 1080px;
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0px;
  filter: brightness(0.2);
}

/* fin - Diseño servicios */



/* diseño responsivo */


@media only screen and (min-width:200px) and (max-width:550px){
  #panel{
    left: 25px;
    bottom: 10px !important;
  }

  .contenedor{
    margin: 0 5%;
    padding: 2%;
  }
}

@media only screen and (min-width:551px) and (max-width:850px){

  .contenedor{
    margin: 0 5%;
    padding: 2%;
    opacity: 1;
  }

}

@media only screen and (min-width:851px) and (max-width:1024px){
  .header-div{
    width: 80%;
    height: 5px;
    background-color: white;
    left: 20%;
    position: relative;
  }

  .contenedor{
    margin: 0 10%;
    padding: 2%;
  }
}

@media only screen and (min-width:1025px){
  .header-div{
    width: 80%;
    height: 5px;
    background-color: white;
    left: 20%;
    position: relative;
  }

  .contenedor{
    margin: 0 10%;
    padding: 2%;
  }
}

/* fin - diseño responsivo */
