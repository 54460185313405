/* aqui empieza el diseño del blog */

  #collapsiblePadre{
    display: flex;
  }

  @media only screen and (min-width:851px) and (max-width:1024px){

    #collapsible{
      width: 100% !important;
    }

    #contenedorPadre{
      display: flex;
      flex-direction: column;
    }

    #contenedorBlog{
      width: 650px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
      overflow: hidden;
    }
    
    #tituloBlog{
      width: 400px;
      text-align: center;
      position: absolute;
      color: white;
      opacity: 0;
    }
  
    #titulo-blog{
      position: absolute;
      color: white;
    }
    
    .imagen-blog{
      z-index: -1;
      background-color: rgba(0,0,0,0.8);
      max-width: 100%;
      filter: brightness(0.4);
    }
  }


  @media only screen and (min-width:200px) and (max-width:550px){

    #collapsible{
      width: 94% !important;
      margin: 3% !important;
    }
  
    #collapsiblePadre{
      display: flex;
      flex-direction: column;
    }
  
    #contenedorBlog{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0 !important;
      overflow: hidden;
    }
    
    #tituloBlog{
      width: 200px;
      text-align: center;
      position: absolute;
      color: white;
      opacity: 0;
      font-size: 15px;
    }
  
    #textoBlog{
      width: 80% !important;
    }
  
    #contenedorBlog #tituloBlog{
      opacity: 1;
      transition: opacity 1.5s ease;
    }
  
    #contenedorBlog img{
      -webkit-filter: brightness(0.4);
      filter: brightness(0.4);
      transition: filter 1.5s ease;
      -webkit-transform:scale(1.05);
      transform:scale(1.05);
      transition: transform 1.5s ease;
    }
  
    #titulo-blog{
      position: absolute;
      color: white;
      font-size: 20px !important;
    }
    
    .imagen-blog{
      z-index: -1;
      background-color: rgba(0,0,0,0.8);
      max-width: 100%;
      max-height: 100%;
      filter: brightness(0.4);
    }
  
  }
  
  @media only screen and (min-width:551px) and (max-width:850px){
    
    #contenedorBlog{
      width: 450px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 18px;
      overflow: hidden;
    }
    
    #tituloBlog{
      width: 250px;
      text-align: center;
      position: absolute;
      color: white;
      opacity: 0;
      font-size: 18px;
    }
  
    #contenedorBlog #tituloBlog{
      opacity: 1;
      transition: opacity 1.5s ease;
    }
  
    #contenedorBlog img{
      -webkit-filter: brightness(0.4);
      filter: brightness(0.4);
      transition: filter 1.5s ease;
      -webkit-transform:scale(1.05);
      transform:scale(1.05);
      transition: transform 1.5s ease;
    }
  
    #titulo-blog{
      position: absolute;
      color: white;
      font-size: 40px !important;
    }
    
    .imagen-blog{
      z-index: -1;
      background-color: rgba(0,0,0,0.8);
      max-width: 100%;
      max-height: 100%;
      filter: brightness(0.4);
    }
  
  }
  
  @media only screen and (min-width:1025px){
  
    #contenedorBlog{
      width: 650px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
      overflow: hidden;
    }
    
    #tituloBlog{
      width: 400px;
      text-align: center;
      position: absolute;
      color: white;
      opacity: 0;
    }
  
    #titulo-blog{
      position: absolute;
      color: white;
    }
    
    .imagen-blog{
      z-index: -1;
      background-color: rgba(0,0,0,0.8);
      max-width: 100%;
      filter: brightness(0.4);
    }
  }
  
  #titulo-blog-icono{
    position: absolute;
    color: white;
  }
  
  #contenedor-blog{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  #imagenBlog{
    width: 100%;
    height: 100%;
  }
  
  #contenedorBlog:hover #tituloBlog{
    opacity: 1;
    transition: opacity 1.5s ease;
  }
  
  #contenedorBlog:hover img{
    -webkit-filter: brightness(0.4);
    filter: brightness(0.4);
    transition: filter 1.5s ease;
    -webkit-transform:scale(1.05);
    transform:scale(1.05);
    transition: transform 1.5s ease;
  }
  
  #header a {
    font-family: 'Montserrat';
    font-size: 20px !important;
    margin: 0 20px;
    box-shadow: none;
  }
  
  #blogContenidoContenedor{
    margin: 100px 25%;
  }
  
  /* aqui termina el diseño del blog */